<template>
  <b-overlay
      :show="showOverlay"
      rounded="sm"
  >

    <div v-if="products !== null">

      <!-- Table Container Card -->
      <b-card
          no-body
          class="mb-3"
      >

        <h1 class="m-2">لیست محصولات</h1>

        <b-table
            ref="refUserListTable"
            class="position-relative"
            :items="products"
            responsive
            :fields="myTableColumns"
            primary-key="id"
            show-empty
            bordered
            striped
            empty-text="موردی موجود نیست!"
        >
          <template #cell(product.productId)="data">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                    size="45"
                    :src="`https://api.maxterms.com/media/gallery/product/${data.item.product.imageFile}`"
                    :variant="`success`"
                />
              </template>
              <small class="text-muted">@{{ data.item.product.productId }}</small>
            </b-media>
          </template>

          <!-- Column: state -->
          <template #cell(state)="data">
            <b-badge
                v-if="data.item.state"
                pill
                class="text-capitalize"
                :variant="`danger`"
            >
              مسدود شده
            </b-badge>
            <b-badge
                v-else
                pill
                class="text-capitalize"
                :variant="`success`"
            >
              مسدود نشده
            </b-badge>
          </template>


          <!-- Column: block -->
          <template #cell(block)="data">
            <b-form-checkbox
                :checked="data.item.state === true ? true : false"
                name="check-button"
                @change="switchState(data.item.state,data.item.product.productId)"
                switch
                inline
            >
            </b-form-checkbox>
          </template>

        </b-table>

        <div class="mx-2 mb-2">
          <b-form-group
              label="توضیحات"
              label-for="description"
          >
            <b-form-textarea
                id="description"
                v-model="toggleData.description"
            />
          </b-form-group>
        </div>

        <div class="mx-4 mb-2">
          <b-row>
            <b-button
                variant="primary"
                class="mt-1 mb-sm-0 mr-0 mr-sm-1 col-12 col-md-2"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="submitToggles"
            >
              ثبت
            </b-button>
          </b-row>
        </div>

        <div class="mx-2 mb-2">
          <b-row>

            <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <!--            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>-->
            </b-col>
            <!-- Pagination -->
            <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                  v-model="currentPage"
                  :total-rows="totalCounts"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon style="transform: rotate(180deg)"
                                icon="ChevronLeftIcon"
                                size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>
    </div>
  </b-overlay>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BOverlay, BFormCheckbox, BFormTextarea, BFormGroup
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Helper from "@/libs/Helper";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {
  UserProductBlockGetBlockedProductsRequest,
  UserProductBlockToggleProductBlockRequest
} from "@/libs/Api/UserProductBlock";

export default {
  title: "مسدودسازی کاربر - پنل ادمین مکس ",
  name: "UserBlock",
  data() {
    return {
      userId: this.$route.params.id,
      products: null,
      showOverlay: false,
      currentPage: 1,
      totalCounts: null,
      perPage: 50,
      toggleData: {
        blockedProductIds: [],
        unblockedProductIds: [],
        userId: this.$route.params.id,
        description: ""
      },
      perPageOptions: [10, 20, 30, 40, 50],
      myTableColumns: [
        {key: 'product.productId', label: 'شناسه محصول'},
        {key: 'product.title', label: 'نام محصول'},
        {key: 'state', label: 'وضعیت'},
        {key: 'block', label: 'مسدودسازی'},
      ],
      searchCommand: '',
      isAddNewUserSidebarActive: false,
      Cart: [],
    }
  },
  computed: {
    CartTotal() {
      let result = 0;
      this.Cart.forEach((item) => {
        result += item.amount * item.count
      })
      return result;
    },
    currentDate() {
      let today = new Date();
      let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
      let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      return date + ' ' + time;
    },
  },
  async created() {
    await this.getProducts(this.userId, this.perPage, this.currentPage);
  },
  methods: {
    switchState(state, productId) {
      let _this = this;

      if (!state && !_this.toggleData.blockedProductIds.includes(productId)) {
        _this.toggleData.blockedProductIds.push(productId);

        const idx = _this.toggleData.unblockedProductIds.findIndex(e => e === productId)
        if (idx !== -1) {
          _this.toggleData.unblockedProductIds.splice(idx, 1);
        }
      } else {
        _this.toggleData.unblockedProductIds.push(productId);

        const idx = _this.toggleData.blockedProductIds.findIndex(e => e === productId)
        if (idx !== -1) {
          _this.toggleData.blockedProductIds.splice(idx, 1);
        }
      }
    },
    async getProducts(userId, count, pageNumber) {
      let _this = this;
      _this.showOverlay = true;

      let getProductData = {
        pageNumber: 0,
        count: 0,
        userId: userId,
      }

      let userProductBlockGetBlockedProductsRequest = new UserProductBlockGetBlockedProductsRequest(_this);
      userProductBlockGetBlockedProductsRequest.setParams(getProductData)
      await userProductBlockGetBlockedProductsRequest.fetch(function (content) {
        _this.showOverlay = false;
        _this.products = content;
      }, function (content) {
        _this.showOverlay = false;
        console.log(content)
      });
    },
    makePrettyPrice(param) {
      return Helper.prettyPrice(param, 0)
    },
    createJalaliDate(param) {
      return new Date(param).toLocaleDateString("fa-IR",);
    },
    async submitToggles() {
      let _this = this;
      _this.showOverlay = true;

      let userProductBlockToggleProductBlockRequest = new UserProductBlockToggleProductBlockRequest(_this);
      userProductBlockToggleProductBlockRequest.setData(_this.toggleData);
      await userProductBlockToggleProductBlockRequest.fetch(function (content) {
        _this.showOverlay = false;
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            icon: 'CheckIcon',
            variant: 'success',
            text: `عملیات انجام شد.`,
          },
        });
        window.location.reload();
      }, function (error) {
        _this.showOverlay = false;
        console.log(error)
      })
    },
  }
  ,
  watch: {}
  ,
  components: {

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    BFormCheckbox,
    BFormTextarea,
    BFormGroup,

    vSelect,
  }
  ,
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>
